import { useEffect, useState } from 'react';
import './AdGenerator.css';

function AdGenerator () {
    const [adNum, setAdNum] = useState(0);
    const [isMouseEntered, setIsMouseEntered] = useState(false);
    const [timer, setTimer] = useState(0);

    const ads = [
        {
            link: 'https://www.cloudways.com/affiliate/scripts/imp.php?id=1724013&amp;a_bid=58da17cf', 
            img: 'https://cloudways.com/affiliate/accounts/default1/banners/58da17cf.jpg'
        },
        {
            link: 'https://www.cloudways.com/affiliate/scripts/imp.php?id=1724013&amp;a_bid=4869f424', 
            img: 'https://www.cloudways.com/affiliate/accounts/default1/banners/4869f424.jpg'
        },
        {
            link: 'https://www.cloudways.com/affiliate/scripts/imp.php?id=1724013&amp;a_bid=aa32e3fe',
            img: 'https://www.cloudways.com/affiliate/accounts/default1/banners/aa32e3fe.jpg'
        },
        {
            link: 'https://www.yourfirstfunnelchallenge.com/yffc?aff=e4da714fadb2e9223f0df9a34a28d7b3b0ac5ed1f76a4c2386de4e67c106995a-1WzEwLDEwNjM2OF02',
            img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/first_funnel_challenge.jpg?v=1718037045'
        },
        {
            link: 'https://www.getresponse.com/?a=qEc9rVcpNH',
            img: 'https://cdn.shopify.com/s/files/1/0738/2987/6008/files/GetResponse-avis.jpg?v=1718036898'
        }
    ];

    useEffect(() => {
        setAdNum(Math.floor(Math.random() * ads.length));

        setInterval(() => {
            setTimer(prev => prev + 1);
        }, 10000)
    }, []);

 
    useEffect(() => {
        if (isMouseEntered) return;
        setAdNum(Math.floor(Math.random() * ads.length));
    }, [timer])
 


    return (
        <div>
            <a href={ads[adNum]['link']} target='_blank'>
                <img  onMouseEnter={() => setIsMouseEntered(true)} onMouseLeave={()=> setIsMouseEntered(false)} src={ads[adNum]['img']} />
            </a>
        </div>
    );
}

export default AdGenerator;