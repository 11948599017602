import AdGenerator from '../AdGenerator/AdGenerator';
import './HeaderAd.css';

function HeaderAd () {
    return (
        <div className='header-ad'>
            <AdGenerator />
        </div>
    )
}

export default HeaderAd;